import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['plan', 'price', 'period', 'features'];
  static values = {
    plans: Array,
  };

  connect() {
    this.planTarget.onchange = this.changePlan.bind(this);
    this.periodTarget.onchange = this.changePlan.bind(this);
  }

  changePlan() {
    const plan = this.findPlan();
    if (!plan) return;

    const period = this.periodTarget.value;
    this.priceTarget.value = SimpleMaskMoney.formatToMask(
      plan[`${period}_price`] * 100
    );

    const featureIds = plan.features.map((feature) => feature.id);
    this.featuresTargets.map(
      (el) => (el.checked = featureIds.includes(parseInt(el.value)))
    );
  }

  findPlan() {
    const planId = this.planTarget.value;
    return this.plansValue.find((plan) => plan.id === parseInt(planId));
  }
}
