import { Controller } from '@hotwired/stimulus';
import IMask from 'imask';

export default class extends Controller {
  static targets = ['input'];
  static values = {
    mask: String,
  };

  connect() {
    const input = this.inputTarget;
    var maskOptions = {
      mask: this.maskValue,
    };
    IMask(input, maskOptions);
  }
}
