import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["select"];
  static values = { options: Array, selecteds: Array };

  connect() {
    new TomSelect(this.selectTarget, {
      valueField: "id",
      labelField: "full_name",
      searchField: ["full_name"],
      maxItems: 1,
      loadThrottle: 1000,
      options: this.optionsValue,
      items: this.selectedsValue,
      load: function (query, callback) {
        const url = "/courses?name=" + encodeURIComponent(query);
        const headers = { "Content-Type": "application/json" };
        fetch(url, { headers })
          .then((response) => response.json())
          .then((json) => callback(json))
          .catch(() => callback);
      },
    });
  }
}
