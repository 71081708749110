import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  connect() {}

  submitForm(event) {
    if (!this.validateForm()) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  validateForm() {
    let isValid = true;

    let requiredFieldSelectors = 'textarea:required, input:required';
    let requiredFields = this.formTarget.querySelectorAll(
      requiredFieldSelectors
    );

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.style.borderColor = 'red';

        isValid = false;

        return false;
      }
    });

    // If we already know we're invalid, just return false
    if (!isValid) {
      return false;
    }

    // Search for any browser invalidated input fields and focus them
    let invalidFields = this.formTarget.querySelectorAll('input:invalid');

    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.style.borderColor = 'red';

        isValid = false;
      }
    });

    return isValid;
  }
}
