import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ['list'];
  static values = {
    input: String,
    childrenSelector: String,
    currentEvent: String,
    currentQuestion: String,
  }

  connect() {
    const scope = this;
    this.sortable = Sortable.create(this.listTarget, {
      animation: 150,
      ghostClass: 'btn-primary',
      handle: '.handle',
      onSort: () => scope.onSort(scope),
    });
  }

  onSort(scope) {
    const selector = scope.inputValue;
    const event = scope.currentEventValue;
    const question = scope.currentQuestionValue;
    const allItems = scope.listTarget.querySelectorAll(
      scope.childrenSelectorValue
    );

    const data = {};
    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i];
      data[item.querySelector(this.inputValue).dataset.objectId] = i;
      item.querySelector(selector).value = i;
    }

    if (question.length == 0) {
      fetch(`/backoffice/events/${event}/questions/sort`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ questions: data })
      })}
      else {
        fetch(`/backoffice/events/${event}/questions/${question}/answers/sort`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ answers: data })
        })
      };
  }
};