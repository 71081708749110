import { Controller } from '@hotwired/stimulus';

import { USD } from '@dinero.js/currencies';
import { dinero, add, multiply, toDecimal } from 'dinero.js';

export default class extends Controller {
  static targets = [
    'childContainer',
    'mainPrice',
    'extraPrice',
    'totalExtraPrice',
    'numberOfExtras',
    'totalPrice',
  ];

  static values = {
    mainPrice: Number,
    extraPrice: Number,
    maxExtraPeople: Number,
  };

  fees = 0;
  extraItemPrice = 0;

  mainPriceCurrency;
  extraPriceCurrency;
  extraPeopleNumber;
  extraPeopleTotalPrice;
  signUpTotalPrice;

  connect() {
    const scope = this;
    if (this.hasChildContainerTarget) {
      const observer = new MutationObserver(() => scope.updateReview(scope));

      observer.observe(this.childContainerTarget, {
        subtree: false,
        childList: true,
      });
    }

    document.querySelector('#event_sign_up_first_name').onkeyup = function (e) {
      document.querySelector('#card_first_name').value = e.target.value;
    };
    document.querySelector('#event_sign_up_last_name').onkeyup = function (e) {
      document.querySelector('#card_last_name').value = e.target.value;
    };

    this.updateReview();
  }

  updateReview(scope = this) {
    if (scope.target) scope = this;
    scope.setupCurrencies(scope);
    scope.showStaticValues(scope);
    scope.calcPrices(scope);
    scope.calcExtraItemPrices(scope);
    scope.setupFees(scope);
    scope.calcPrices(scope);
    scope.showPriceValues(scope);
  }

  calcExtraItemPrices(scope) {
    let extraItemPrice = 0.0;
    for (const element of document.querySelectorAll(
      '.extra-item-check:checked'
    )) {
      extraItemPrice += parseFloat(element.dataset.price);
    }
    scope.extraItemPrice = extraItemPrice;
    document.querySelector('.extra-items-total td:last-child').innerText =
      scope.formatMoney(
        dinero({
          amount: scope.extraItemPrice * 100,
          currency: USD,
        })
      );
  }

  setupFees(scope) {
    scope.fees = 0;
    for (const feeTarget of document.querySelectorAll('.event-fee')) {
      const feePrice = parseFloat(feeTarget.dataset.feeFixedPrice) || 0;
      const feePercent = parseFloat(feeTarget.dataset.feePercent) || 0;
      let totalFee = feePrice + feePercent / 100;
      scope.fees += totalFee;
      feeTarget.querySelector('.fee-value').innerText = scope.formatMoney(
        dinero({
          amount: totalFee * 100,
          currency: USD,
        })
      );
    }
  }

  showPriceValues(scope) {
    if (scope.hasTotalExtraPriceTarget)
      scope.totalExtraPriceTarget.innerHTML = scope.formatMoney(
        scope.extraPeopleTotalPrice
      );

    scope.totalPriceTarget.innerHTML = scope.formatMoney(
      scope.signUpTotalPrice
    );
  }

  calcPrices(scope) {
    scope.extraPeopleTotalPrice = dinero({
      amount: 0,
      currency: USD,
    });

    if (scope.hasChildContainerTarget) {
      scope.extraPeopleTotalPrice = multiply(
        scope.extraPriceCurrency,
        scope.extraPeopleNumber
      );
    }

    let totalSignUp = add(scope.mainPriceCurrency, scope.extraPeopleTotalPrice);
    totalSignUp = add(
      totalSignUp,
      dinero({
        amount: parseInt((scope.fees + parseFloat(scope.extraItemPrice)) * 100),
        currency: USD,
      })
    );
    scope.signUpTotalPrice = totalSignUp;
  }

  showStaticValues(scope) {
    if (!scope.hasChildContainerTarget) return;

    scope.extraPeopleNumber =
      scope.childContainerTarget.querySelectorAll('.extra-person').length;

    scope.mainPriceTarget.innerHTML = scope.formatMoney(
      scope.mainPriceCurrency
    );
    scope.extraPriceTarget.innerHTML = scope.formatMoney(
      scope.extraPriceCurrency
    );
    scope.numberOfExtrasTarget.innerHTML = scope.extraPeopleNumber;
  }

  setupCurrencies(scope) {
    scope.mainPriceCurrency = dinero({
      amount: scope.mainPriceValue * 100,
      currency: USD,
    });
    scope.extraPriceCurrency = dinero({
      amount: scope.extraPriceValue * 100,
      currency: USD,
    });
  }

  formatMoney(money) {
    return toDecimal(
      money,
      ({ value, currency }) => `${currency.code} ${value}`
    );
  }
}
