import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  next(event) {
    if (event.detail.success) {
      Turbo.clearCache();
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }
}
