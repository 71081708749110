import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    title: String,
    text: String,
    cancel: String,
    confirm: String,
    icon: String,
  };

  connect() {
    this.confirmed = false;
    this.buttonTarget.dataset.action = "sweet-alert#click";
  }

  click(event) {
    if (this.confirmed !== false) return;

    event.preventDefault();
    const that = this;
    Swal.fire({
      title: this.titleValue,
      text: this.textValue,
      icon: this.iconValue,
      showCancelButton: this.cancelValue ? true : false,
      confirmButtonColor: "#3b82f6",
      reverseButtons: true,
      buttons: {
        cancel: this.cancelValue,
        confirm: this.confirmValue,
      },
    }).then((result) => {
      if (!result.isConfirmed) return;

      that.confirmed = true;
      that.buttonTarget.click();
    });
  }
}
