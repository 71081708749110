import { Controller } from "@hotwired/stimulus";

import { USD } from "@dinero.js/currencies";
import { dinero, toDecimal } from "dinero.js";

export default class extends Controller {
  connect() {
    this.updateTotals();
    this.updateEventIds();
    for (let checkbox of document.querySelectorAll("[name*=events]")) {
      checkbox.addEventListener("change", () => {
        this.updateEventIds();
        this.updateTotals();
      });
    }
    for (let payNowButton of document.querySelectorAll(".pay-now")) {
      payNowButton.addEventListener("click", () => {
        document.querySelector(".modal").classList.remove("hidden");
        document.querySelector(".modal-backdrop").classList.remove("hidden");
      });
    }
  }

  updateEventIds() {
    const eventIds = [...document.querySelectorAll("[name*=events]:checked")]
      .map((e) => e.value)
      .join(",");
    document.querySelector("#event-ids-input").value = eventIds;
  }

  updateTotals() {
    let total = 0;
    for (let checkbox of document.querySelectorAll("[name*=events]")) {
      if (checkbox.checked) {
        const price = parseFloat(checkbox.dataset.price);
        total += price;
      }
    }

    for (let totalBox of document.querySelectorAll(".total")) {
      totalBox.innerHTML = this.formatMoney(total);
    }
  }

  formatMoney(money) {
    return toDecimal(
      dinero({
        amount: parseInt(money * 100),
        currency: USD,
      }),
      ({ value }) => `$${value}`
    );
  }
}
