import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  static targets = ['input'];
  static values = { format: String };

  connect() {
    const format = this.formatValue || 'datetime';
    // alert(this.formatValue);
    const input = this.inputTarget;
    flatpickr(input, this[`${format}Config`]());
  }

  datetimeConfig() {
    return {
      enableTime: true,
      altFormat: 'm/d/Y h:i K',
      dateFormat: 'Y-m-d h:i K',
      altInput: true,
    };
  }

  dateConfig() {
    return {
      altFormat: 'm/d/Y',
      dateFormat: 'Y-m-d',
      altInput: true,
    };
  }
}
