import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    text: String,
  };

  connect() {
    const text = this.text;
    this.element.addEventListener("click", function (e) {
      e.preventDefault();
      this.innerText = text || this.innerText + "...";
      this.disabled = "disabled";

      if (e.target?.form) e.target.form.submit();
    });
  }
}
