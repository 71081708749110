import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  static values = {
    alertType: String,
    dueValue: String,
    dueDate: String,
    daysRemaining: String,
    accountId: String,
    accountName: String,
  };

  connect() {
    this.controlString = new Date().toISOString().split("T")[0];
    this.controlKey = `paymentReminderShow_${this.accountIdValue}`;
    window.remindMeLater = function () {
      localStorage.setItem(this.controlKey, this.controlString);
      Swal.close();
    }.bind(this);

    if (this.alertTypeValue === "alert") return this.showCountdownAlert();

    if (this.alertTypeValue === "block") return this.showBlockAlert();
  }

  showBlockAlert() {
    Swal.fire({
      title: "Payment notice",
      text: `${this.accountNameValue} has an overdue of payment of ${this.dueValue}. Please pay this amount to continue.`,
      showCancelButton: false,
      cancelButtonText: "Close",
      allowOutsideClick: false,
      confirmButtonText: "Make a payment",
    });
  }

  showCountdownAlert() {
    if (localStorage.getItem(this.controlKey) === this.controlString) return;

    Swal.fire({
      icon: "warning",
      title: "Payment reminder",
      html: `<h2 class="text-secondary">Amount Due: ${this.dueValueValue}</h2>\
               <h2 class="text-secondary">Due Date: ${this.dueDateValue}</h2>\
               <h1 class="text-orange my-4">${this.daysRemainingValue} Days Remaining</h1>\
               <div class="d-inline-block">\
                 <a href="/backoffice/payments/new" class="btn btn-primary mr-2">Pay now</a>\
                 <button class="btn bg-amber-500 text-white" onclick="window.remindMeLater()">Remind Me Next Time</button>\
               </div>`,
      showCancelButton: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  }
}
