import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("label.required").forEach((element) => {
      if (element.querySelector(".required-hint")) return;

      const requiredHint = document.createElement("span");
      requiredHint.classList.add("text-xs");
      requiredHint.classList.add("text-red-600");
      requiredHint.classList.add("ml-1");
      requiredHint.classList.add("required-hint");
      requiredHint.innerText = "(Required)";
      element.append(requiredHint);
    });

    this.element.querySelectorAll("label.optional:not(.required):not(.tbd)").forEach((element) => {
      if (element.querySelector(".optional-hint")) return;

      const requiredHint = document.createElement("span");
      requiredHint.classList.add("text-xs");
      requiredHint.classList.add("text-slate-400");
      requiredHint.classList.add("ml-1");
      requiredHint.classList.add("optional-hint");
      requiredHint.innerText = "(Optional)";
      element.append(requiredHint);
    });
  }
}
