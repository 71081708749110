import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    orderId: String,
  };
  static targets = ["loader"];

  connect() {
    if (window.paypal) {
      return this.init();
    }

    const interval = setInterval(() => {
      if (window.paypal) {
        clearInterval(interval);
        this.init();
      }
    }, 1000);
  }

  init() {
    const cardField = this.getCardField();

    this.loaderTarget.classList.add("hidden");
    if (cardField.isEligible()) {
      this.mountCard(cardField);
    }
  }

  getCardField() {
    return window.paypal.CardFields({
      createOrder: this.createOrderCallback.bind(this),
      onApprove: this.onApproveCallback.bind(this),
      onError: this.onError.bind(this),
      style: {
        input: {
          "font-size": "12px",
          padding: "5px 10px",
        },
      },
    });
  }

  mountCard(cardField) {
    const nameField = cardField.NameField({ placeholder: "Cardholder Name" });
    nameField.render("#card-name-field-container");

    const numberField = cardField.NumberField();
    numberField.render("#card-number-field-container");

    const cvvField = cardField.CVVField();
    cvvField.render("#card-cvv-field-container");

    const expiryField = cardField.ExpiryField();
    expiryField.render("#card-expiry-field-container");

    document.getElementById("submit-review").addEventListener("click", (e) => {
      async function submit() {
        const { errors } = await cardField.getState();
        if (errors.includes("INVALID_NAME")) {
          return this.resultMessage(`Cardholder Name is required`);
        }
        cardField.submit().catch((error) => {
          this.resultMessage(`Sorry, your transaction could not be processed...`);
        });
      }
      e.preventDefault();
      e.target.innerText = e.target.innerText + "...";
      e.target.disabled = "disabled";
      submit.bind(this)();
    });
  }

  createOrderCallback() {
    try {
      return this.orderIdValue;
    } catch (error) {
      console.error(error);
      this.resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
    }
  }

  onApproveCallback(data, actions) {
    const input = document.getElementById("order_id");
    input.value = data.orderID;
    input.form.submit();
  }

  onError(error) {}

  resultMessage(message) {
    const container = document.querySelector("#result-message");
    document.getElementById("submit-review").innerText = "Pay and Sign up";
    container.classList.remove("hidden");
    container.innerHTML = message;
  }
}
