import { Controller } from "@hotwired/stimulus";
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ["element", "input", "clear"];

  connect() {
    const input = this.inputTarget;
    const clear_button = this.clearTarget;

    clear_button.addEventListener("click", function () {
      const color = "#e5e7eb";
      input.value = color;
      input.parentElement.querySelector(
        ".pcr-button"
      ).style = `transition: none 0s ease 0s; --pcr-color:${color};`;
    });

    Pickr.create({
      el: this.elementTarget,
      theme: "nano",
      comparison: false,
      default: input.value,
      useAsButton: false,
      swatches: [
        "rgb(244, 67, 54)",
        "rgb(233, 30, 99)",
        "rgb(156, 39, 176)",
        "rgb(103, 58, 183)",
        "rgb(63, 81, 181)",
        "rgb(33, 150, 243)",
        "rgb(3, 169, 244)",
        "rgb(0, 188, 212)",
        "rgb(0, 150, 136)",
        "rgb(76, 175, 80)",
        "rgb(139, 195, 74)",
        "rgb(205, 220, 57)",
        "rgb(255, 235, 59)",
        "rgb(255, 193, 7)",
      ],
      components: {
        preview: true,
        opacity: false,
        hue: false,
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: false,
        },
      },
    }).on("change", (color) => (input.value = color.toHEXA()));
  }
}
