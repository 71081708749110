import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    applicationId: String,
    locationId: String,
  };
  static targets = ["cardTokenInput"];

  connect() {
    if (window.Square) {
      return this.init();
    }
  }

  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  async init() {
    const payments = window.Square.payments(this.applicationIdValue, this.locationIdValue);
    const card = await this.initializeCard(payments);
    document.querySelector("#submit-review").addEventListener(
      "click",
      (async (e) => {
        e.preventDefault();
        e.target.innerText += "...";
        e.target.disabled = "disabled";

        const token = await this.tokenize(card);
        this.cardTokenInputTarget.value = token;
        document.querySelector("form").submit();
      }).bind(this)
    );
  }

  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach("#card-container");

    return card;
  }
}
