import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["list"];
  static values = {
    inputSelector: String,
    childrenSelector: String,
  };

  connect() {
    const scope = this;
    window.sortable = Sortable.create(this.listTarget, {
      animation: 150,
      // ghostClass: "btn-primary",
      // direction: "horizontal",
      // handle: ".handle",
      swapThreshold: 0.5,
      onSort: () => scope.onSort(scope),
    });
  }

  onSort(scope) {
    const selector = scope.inputSelectorValue;
    const allItems = scope.listTarget.querySelectorAll(scope.childrenSelectorValue);

    const data = {};
    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i];
      data[item.querySelector(this.inputSelectorValue).dataset.eventId] = i;
      item.querySelector(selector).value = i;
    }
    fetch("/backoffice/events/sort", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ events: data }),
    });
  }
}
