import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    frame: String,
    hidden: Boolean,
  };

  submitEnd(e) {
    if (e.detail.success) {
      this.close();
    }
  }

  close() {
    if (this.hiddenValue) {
      this.element.classList.add("hidden");
      this.element.parentElement.querySelector(".modal-backdrop").classList.add("hidden");
      return;
    }

    const element = this.element;
    element.removeAttribute("reloadable");
    element.removeAttribute("src");
    element.parentElement.innerHTML = "";
  }
}
