import '@fullcalendar/core/vdom';
import { Controller } from '@hotwired/stimulus';
import { Calendar, constrainPoint } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

export default class extends Controller {
  static values = { events: Array };
  static targets = ['calendar'];

  connect() {
    const calendarEl = this.calendarTarget;
    const eventColor = window.getComputedStyle(
      document.querySelector('.text-primary-500')
    ).color;

    let calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      initialDate: new Date(),
      aspectRatio: 2,
      eventColor,
      headerToolbar: {
        center: '',
        left: 'title',
        right: 'prev,next',
      },
      buttonText: {
        today: 'Today',
        dayGridMonth: 'Month',
        timeGridWeek: 'Week',
        timeGridDay: 'Day',
        listMonth: 'List Month',
      },
      navLinks: true,
      businessHours: true,
      editable: true,
      selectable: true,
      events: this.eventsValue,
    });
    calendar.render();
  }
}
