import { Controller } from '@hotwired/stimulus';
import { render } from 'preact/compat';

export default class extends Controller {
  static targets = ['input', 'preview', 'button', 'trash', 'remove'];
  static values = {
    image: String,
  };

  connect() {
    const remove = this.removeTarget;
    const preview = this.previewTarget;
    const trashIcon = this.trashTarget;

    if (!this.imageValue || this.imageValue.length === 0)
      this.previewTarget.style.display = 'none';
    else {
      this.previewTarget.src = this.imageValue;
    }

    const scope = this;

    trashIcon.addEventListener('click', function () {
      scope.setRemoveImageInput('true');
    });
  }

  chooseFile() {
    this.inputTarget.click();
  }

  fileChosen(event) {
    const target = this.previewTarget;
    target.style.display = 'block';
    this.fileToDataUrl(event, (src) => (target.src = src));
  }

  fileToDataUrl(event, callback) {
    if (!event.target.files.length) return;

    let file = event.target.files[0],
      reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (e) => callback(e.target.result);
  }

  setRemoveImageInput(value) {
    const placeholder = `https://via.placeholder.com/${this.previewTarget.width}x${this.previewTarget.height}?text=Prime%20Sign%20Up`;
    this.removeTarget.defaultValue = this.removeTarget.value = value;
    if (!this.imageValue || this.imageValue.length === 0)
      return this.previewTarget.style.display = 'none';
    this.previewTarget.src = placeholder;
  }
}
