import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { slotType: String };

  addDate() {
    this.addRowOrColumn('dates');
  }

  addSlot() {
    this.addRowOrColumn('slots');
  }

  handleWithBySlot(className) {
    if (className == 'slots') {
      const newSlot = document
        .querySelector('tbody.slots:last-child')
        .cloneNode(true);
      this.removeReadOnly(newSlot);
      newSlot
        .querySelectorAll('.slot_group_index')
        .forEach(
          (el) => (el.value = document.querySelectorAll('tbody.slots').length)
        );
      this.setIdsAsEmpty(newSlot);
      document
        .querySelector('tbody.slots:last-child')
        .parentElement.appendChild(newSlot);
      return;
    }

    for (const element of document.querySelectorAll('tbody.slots')) {
      const newRow = element.querySelector('tr:last-child').cloneNode(true);
      element.appendChild(newRow);
      const rowSpan = element.querySelectorAll('tr').length;
      newRow.parentElement
        .querySelector('td[rowspan]')
        .setAttribute('rowspan', rowSpan);
      this.setIdsAsEmpty(element);
      this.removeReadOnly(newRow);
      element
        .querySelectorAll('tr:not(:first-child) td[rowspan]')
        .forEach((el) => el.remove());
      element
        .querySelectorAll('tr:first-child')
        .forEach(
          (el) =>
            el.classList.remove('border-none') && el.classList.add('border-t-1')
        );
      element
        .querySelectorAll('tr:not(:first-child)')
        .forEach(
          (el) =>
            el.classList.add('border-none') && el.classList.remove('border-t-1')
        );
    }
  }

  addRowOrColumn(className) {
    if (this.slotTypeValue === 'slot') return this.handleWithBySlot(className);

    for (const targetElement of document.getElementsByClassName(className)) {
      const childrens = targetElement.children;
      const element = childrens[childrens.length - 1].cloneNode(true);

      this.setIdsAsEmpty(element);

      this.removeReadOnly(element);

      targetElement.appendChild(element);
    }
  }

  setIdsAsEmpty(element) {
    for (const idElement of element.querySelectorAll('.id')) {
      idElement.value = '';
    }
  }

  removeReadOnly(element) {
    for (const invalidInput of element.querySelectorAll('[readonly]')) {
      invalidInput.remove();
    }
  }
}
